<template>
  <div class="reason-info-card-desktop d-flex align-items-left">
    <img :src="require(`@/assets/images/icons/reason-info/ic_${path}.png`)" />
    <div class="title">
      {{ title }}
    </div>
    <div class="subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>
<script>
export default {
  name: "ReasonInfoCardDesktop",
  props: {
    path: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    subtitle: {
      type: String,
      default: () => "",
    },
  },
};
</script>
<style lang="scss">
.reason-info-card-desktop {
  flex-direction: column;
  text-align: left;
  height: 180px;
  width: calc(100% + 15px);
  color: #414141;

  img {
    width: 48px;
    height: 48px;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin: 10px 0;
  }

  .subtitle {
    font-family: "Avenir Next LT W05 Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
